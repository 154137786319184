import React from 'react'
import { Button } from 'react-bootstrap'
import CreateSeriesModal from '../Modals/CreateSeries'
import SeriesList from '../SeriesList'
import UpdateSeriesModal from '../Modals/UpdateSeries'
import './styles.scss'

const SeriesPanelLayout = ({
  handleNewSeriesTitleChange,
  handleOpenCreateSeriesModal,
  handleCloseCreateSeriesModal,
  handleOpenUpdateSeriesModal,
  handleCloseUpdateSeriesModal,
  isOpenCreateSeriesModal,
  isOpenUpdateSeriesModal,
  handleSeriesCreate,
  handleSeriesDelete,
  handleSeriesUpdate,
  newSeriesTitle,
  series,
  handleSelectBookToAdd,
  handleSeriesBookDelete,
  updatedSeries,
  filteredNotSelectedUpdatedSeriesBooks,
  handleGetSelectedBookIndex,
  seriesBookSearch,
  handleSeriesBookSearchChange,
  handleSeriesBookOrderUpdate,
  handleSeriesBookOrderDragEnd,
}) => {
  return (
    <div className='series'>
      <div className='series-controls'>
        <Button onClick={handleOpenCreateSeriesModal}>Add series</Button>
      </div>
      <CreateSeriesModal
        show={isOpenCreateSeriesModal}
        onHide={handleCloseCreateSeriesModal}
        value={newSeriesTitle}
        onChange={handleNewSeriesTitleChange}
        onSubmit={handleSeriesCreate}
      />
      <UpdateSeriesModal
        onSubmit={handleSeriesUpdate}
        hideHandler={handleCloseUpdateSeriesModal}
        show={isOpenUpdateSeriesModal}
        handleSelectBookToAdd={handleSelectBookToAdd}
        updatedSeries={updatedSeries}
        getSelectedBookIndex
        filteredNotSelectedUpdatedSeriesBooks={
          filteredNotSelectedUpdatedSeriesBooks
        }
        handleGetSelectedBookIndex={handleGetSelectedBookIndex}
        seriesBookSearch={seriesBookSearch}
        handleSeriesBookSearchChange={handleSeriesBookSearchChange}
      />
      <SeriesList
        series={series}
        handleSeriesDelete={handleSeriesDelete}
        handleOpenUpdateSeriesModal={handleOpenUpdateSeriesModal}
        handleSeriesBookDelete={handleSeriesBookDelete}
        handleSeriesBookOrderUpdate={handleSeriesBookOrderUpdate}
        handleSeriesBookOrderDragEnd={handleSeriesBookOrderDragEnd}
      />
    </div>
  )
}

export default SeriesPanelLayout
