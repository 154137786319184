const { gql } = require('@apollo/client')

export const GET_LIST = gql`
  query lists($list_id: String) {
    lists(
      page: 0
      limit: 10
      alias: "lists"
      relations: [
        "booksOrder"
        "booksOrder.book.bookItem"
        "genresOrder"
        "genresOrder.genre"
        "tagsOrder"
        "tagsOrder.tag"
        "bannersOrder"
        "bannersOrder.banner"
        "banner.advertisement"
        "banner.book"
      ]
      where: [{ lists: { list_id: $list_id } }]
      cache: 0
    ) {
      list_id
      list_type
      title
      description
      booksOrder {
        book {
          book_id
          title
          cover_link
          status
        }
        order_number
      }
      genresOrder {
        genre {
          genre_id
          title
        }
        order_number
      }
      tagsOrder {
        tag {
          tag_id
          title
        }
        order_number
      }
      bannersOrder {
        banner {
          banner_id
          banner_type
          cover_url
          book {
            book_id
            title
            cover_link
            status
          }
          advertisement {
            advertisement_id
            title
            link
          }
        }
        order_number
      }
      survey_links
    }
  }
`

export const GET_LISTS = gql`
  query lists($page: Int) {
    lists(
      page: $page
      limit: 100000
      relations: [
        "booksOrder"
        "booksOrder.book.bookItem"
        "genresOrder"
        "genresOrder.genre"
        "tagsOrder"
        "tagsOrder.tag"
        "bannersOrder"
        "bannersOrder.banner"
        "banner.advertisement"
        "banner.book"
      ]
      cache: 0
    ) {
      list_id
      list_type
      title
      description
      booksOrder {
        book {
          book_id
          title
          cover_link
          status
        }
        order_number
      }
      genresOrder {
        genre {
          genre_id
          title
        }
        order_number
      }
      tagsOrder {
        tag {
          tag_id
          title
        }
        order_number
      }
      bannersOrder {
        banner {
          banner_id
          banner_type
          cover_url
          book {
            book_id
            title
            cover_link
            status
          }
          advertisement {
            advertisement_id
            title
            link
          }
        }
        order_number
      }
      survey_links
    }
  }
`

export const CREATE_LIST = gql`
  mutation createList($list: CreateListDto!) {
    createList(
      list: $list
      relations: ["booksOrder", "genresOrder", "tagsOrder", "bannersOrder"]
    ) {
      list_id
      list_type
      title
      description
      booksOrder {
        order_number
      }
      genresOrder {
        order_number
      }
      tagsOrder {
        order_number
      }
      bannersOrder {
        order_number
      }
      survey_links
    }
  }
`

export const UPDATE_LIST = gql`
  mutation updateList($list: UpdateListDto!) {
    updateList(
      list: $list
      relations: [
        "booksOrder"
        "booksOrder.book"
        "genresOrder"
        "genresOrder.genre"
        "tagsOrder"
        "tagsOrder.tag"
        "bannersOrder"
        "bannersOrder.banner"
        "bannersOrder.banner.advertisement"
        "bannersOrder.banner.book"
      ]
    ) {
      list_id
      title
      description
      booksOrder {
        book {
          book_id
          title
          cover_link
          status
        }
        order_number
      }
      genresOrder {
        genre {
          genre_id
          title
        }
        order_number
      }
      tagsOrder {
        tag {
          tag_id
          title
        }
        order_number
      }
      bannersOrder {
        banner {
          banner_id
          banner_type
          cover_url
          book {
            book_id
            title
            cover_link
            status
          }
          advertisement {
            advertisement_id
            title
            link
          }
        }
        order_number
      }
      survey_links
    }
  }
`

export const DELETE_LIST = gql`
  mutation deleteList($list: RemoveListDto!) {
    removeList(list: $list) {
      list_id
    }
  }
`
