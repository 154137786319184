import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Button,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react'
import React from 'react'

const CreateListModal = ({
  isOpen,
  onClose,
  createListFormik,
  sectionTypes,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent as='form' onSubmit={createListFormik.handleSubmit}>
        <ModalHeader>Create List</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl
            mb='20px'
            isInvalid={
              createListFormik.errors.list_type &&
              createListFormik.touched.list_type
            }
          >
            <FormLabel htmlFor='list_type'>List list_type</FormLabel>
            <Select
              id='list_type'
              placeholder='Select type'
              value={createListFormik.values.list_type}
              onChange={createListFormik.handleChange}
            >
              {sectionTypes.map(type => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {createListFormik.errors.list_type}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            mb='20px'
            isInvalid={
              createListFormik.errors.title && createListFormik.touched.title
            }
          >
            <FormLabel htmlFor='title'>List title</FormLabel>
            <Input
              value={createListFormik.values.title}
              onChange={createListFormik.handleChange}
              id='title'
              placeholder='Title'
            />
            <FormErrorMessage>{createListFormik.errors.title}</FormErrorMessage>
          </FormControl>

          <FormControl
            mb='20px'
            isInvalid={
              createListFormik.errors.description &&
              createListFormik.touched.description
            }
          >
            <FormLabel htmlFor='description'>List description</FormLabel>
            <Input
              value={createListFormik.values.description}
              onChange={createListFormik.handleChange}
              id='description'
              placeholder='description'
            />
            <FormErrorMessage>
              {createListFormik.errors.description}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='yellow' mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme='blue'
            type='submit'
            isLoading={createListFormik.isSubmitting}
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CreateListModal
