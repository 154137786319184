import React, { useCallback, useState } from 'react'
import BannersPanelLayout from './components/BannersPanelLayout'
import { useDispatch, useSelector } from 'react-redux'
import { getBannersList } from '../../redux/slices/banners/selectors'
import CreateBannerModal from './components/Modals/CreateBannerModal'
import {
  createBannerThunk,
  updateBannerCoverThunk,
} from '../../redux/slices/banners/thunks'
import { BannerTypes } from '../../constants/banners'

const BannersPanel = () => {
  const dispatch = useDispatch()

  const banners = useSelector(getBannersList)

  const [search, setSearch] = useState('')
  const [isShowBannerCreateModal, setIsShowBannerCreateModal] = useState(false)

  const handleSearchChange = useCallback(e => setSearch(e.target.value), [])

  const handleBannerCreateModalOpen = useCallback(
    () => setIsShowBannerCreateModal(true),
    [],
  )

  const handleBannerCreateModalClose = useCallback(
    () => setIsShowBannerCreateModal(false),
    [],
  )

  const createBannerHandler = useCallback(
    async ({ banner_type, file, attachment, cover_url }) => {
      const banner = {
        banner_type,
        cover_url,
      }

      if (banner_type === BannerTypes.BOOKS) {
        banner.book = attachment
      }
      if (banner_type === BannerTypes.ADVERTISEMENTS) {
        banner.advertisement = attachment
      }

      const { banner_id } = await dispatch(createBannerThunk(banner)).unwrap()

      if (file) {
        await dispatch(updateBannerCoverThunk({ banner_id, formdata: file }))
      }
      handleBannerCreateModalClose()
    },
    [dispatch, handleBannerCreateModalClose],
  )

  return (
    <>
      <BannersPanelLayout
        banners={banners}
        search={search}
        handleSearchChange={handleSearchChange}
        handleBannerCreateModalOpen={handleBannerCreateModalOpen}
      />

      <CreateBannerModal
        isShow={isShowBannerCreateModal}
        onHide={handleBannerCreateModalClose}
        onSubmit={createBannerHandler}
      />
    </>
  )
}

export default BannersPanel
