import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useNavigate } from 'react-router'
import { loginUserThunk } from '../../redux/slices/users/thunks'
import AuthLayout from './components/AuthLayout'
import AuthService from 'services/auth'
import hashPassword from 'utils/hash/hashPassword'
import { getUserInfo } from 'api/users'
import { Box, useToast } from '@chakra-ui/react'
import AuthAlert from './components/AuthAlert'
import { USER_ROLES } from 'constants/users'

const AuthScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useToast()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onEmailChange = useCallback(e => setEmail(e.target.value), [setEmail])

  const onPasswordChange = useCallback(
    e => setPassword(e.target.value),
    [setPassword],
  )
  const onLoginFormSubmit = useCallback(
    async e => {
      e.preventDefault()
      setIsLoading(true)

      const hash = await hashPassword(password)

      try {
        const { access_token } = await dispatch(
          loginUserThunk({ email, password: hash }),
        ).unwrap()

        AuthService.setCredentials({ token: access_token, role: false })

        await getUserInfo()
          .then(({ data }) => {
            if (!Object.hasOwnProperty.call(USER_ROLES, data.role)) {
              toast({
                position: 'top',
                render: ({ onClose }) => (
                  <Box mt='61px'>
                    <AuthAlert
                      onClose={onClose}
                      title='Permission denied!'
                      reason='You haven`t access to this panel'
                    />
                  </Box>
                ),
                duration: 5000,
              })
              AuthService.removeCredentials()
            } else {
              AuthService.setCredentials({ role: data.role, token: false })
              if (data.role === USER_ROLES.SUPPORT) {
                navigate('/comments')
              } else {
                navigate('/')
              }
            }
          })
          .catch(_err => {
            toast({
              position: 'top',
              render: ({ onClose }) => (
                <Box mt='61px'>
                  <AuthAlert
                    onClose={onClose}
                    title='Something went wrong!'
                    reason='An unexpected error occurred'
                  />
                </Box>
              ),
              duration: 5000,
            })
            AuthService.removeCredentials()
          })
      } catch (err) {
        toast({
          position: 'top',
          render: ({ onClose }) => (
            <Box mt='61px'>
              <AuthAlert
                onClose={onClose}
                title='Wrong credentials'
                reason='Check your credentials or try later'
              />
            </Box>
          ),
          duration: 5000,
        })
        AuthService.removeCredentials()
      }

      setIsLoading(false)
    },
    [dispatch, email, navigate, password, toast],
  )

  useEffect(() => {
    const loginButton = document.querySelector('.login-button')
    const passwordField = document.getElementById('password')

    const checkEnterPress = e => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        if (loginButton) {
          passwordField?.blur()
          loginButton.click()
        }
      }
    }

    if (passwordField) {
      passwordField.addEventListener('keypress', checkEnterPress)
    }

    return () => {
      passwordField?.removeEventListener('keypress', checkEnterPress)
    }
  })

  return !AuthService.token ? (
    <AuthLayout
      email={email}
      onEmailChange={onEmailChange}
      onPasswordChange={onPasswordChange}
      password={password}
      onLoginFormSubmit={onLoginFormSubmit}
      isLoading={isLoading}
    />
  ) : (
    <Navigate to='/' />
  )
}
export default AuthScreen
