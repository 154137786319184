import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  useDisclosure,
} from '@chakra-ui/react'
import ArrowIcon from 'components/UI/Icons/ArrowIcon'
import React, { useEffect, useState } from 'react'
import { colors } from 'shared/style/colors'
import { useNavigate } from 'react-router-dom'
import RemoveScreenModal from '../RemoveScreenModal'
import { GET_SECTIONS } from 'services/sections/graphql'
import { useLazyQuery } from '@apollo/client'
import { API_V2 } from 'constants/apollo'
import SectionItem from 'screens/Sections/NewSections/components/SectionsContent/SectionItem'
import CopyItem from './CopyItem'

const ScreenItem = ({ item, handleCopyToClipboard }) => {
  const [hover, setHover] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [itemSections, setItemSections] = useState([])
  const navigate = useNavigate()

  const handleManageSection = () => {
    navigate(`${window.location.pathname}?active=screens&manage=yes`, {
      state: {
        item,
      },
    })
  }

  const [loadSections] = useLazyQuery(GET_SECTIONS, {
    context: {
      clientName: API_V2,
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleLoadSection = async () => {
    const sections = []

    for (const section of item.screenSections) {
      const {
        data: { items },
      } = await loadSections({
        variables: {
          pagination: {
            offset: 0,
            count: 1,
          },
          section: {
            id: section.sectionId,
          },
        },
        context: {
          clientName: API_V2,
        },
      })

      sections.push(items.sections[0])
    }

    setItemSections(sections)
  }

  useEffect(() => {
    if (item.screenSections.length > 0) {
      handleLoadSection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  return (
    <AccordionItem border='none' w='100%'>
      {({ isExpanded }) => (
        <Flex
          bg='white'
          w='100%'
          borderRadius='8px'
          direction='column'
          border={
            hover || isExpanded
              ? `1px solid ${colors['primary-violet-100']}`
              : `1px solid ${colors['primary-gray-30']}`
          }
        >
          <AccordionButton
            _hover={{
              bg: 'white',
            }}
            p='8px 44px'
            m='0'
            borderRadius='8px'
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            outline='none'
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Flex direction='column' flex={1} gap='12px'>
              <Heading
                size='poppins20'
                variant='primary-black-text'
                color={
                  hover || isExpanded
                    ? colors['primary-blue-100']
                    : colors['primary-black-text']
                }
                lineHeight='38px'
                letterSpacing='0'
                textAlign='left'
                fontWeight={400}
                fontSize='16px'
                outline='none'
              >
                Slug: <b>{item.slug}</b>
                <CopyItem
                  handleCopyToClipboard={handleCopyToClipboard}
                  place='Slug'
                  text={item.slug}
                />{' '}
                | Place: <b>{item.type}</b> | Title: <b>{item.title} </b>
                <CopyItem
                  handleCopyToClipboard={handleCopyToClipboard}
                  place='Title'
                  text={item.title}
                />{' '}
                | Is default: <b>{item.isDefault ? 'yes' : 'no'}</b>
              </Heading>
            </Flex>

            {isExpanded ? (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box transform='rotate(180deg)'>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            ) : (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            )}
          </AccordionButton>

          <AccordionPanel>
            <Flex
              bg='white'
              w='100%'
              borderRadius='8px'
              direction='column'
              gap='8px'
            >
              <Flex gap='12px' direction='column' mb='24px'>
                {itemSections.map(item => (
                  <SectionItem
                    key={item.id}
                    item={item}
                    isPreview
                    handleCopyToClipboard={handleCopyToClipboard}
                  />
                ))}
              </Flex>

              <Flex gap='23px' alignSelf='end'>
                <Button
                  type='button'
                  variant='outlineButton'
                  w='204px'
                  borderColor={`${colors['light-red-700']} !important`}
                  color={`${colors['light-red-700']} !important`}
                  _hover={{
                    borderColor: `${colors['light-text-text-danger']} !important`,
                    color: `${colors['light-text-text-danger']} !important`,
                  }}
                  onClick={onOpen}
                >
                  Remove
                </Button>

                <Button
                  type='button'
                  variant='defaultButton'
                  w='204px'
                  onClick={handleManageSection}
                >
                  {'Manage'}
                </Button>
              </Flex>
            </Flex>

            {isOpen && <RemoveScreenModal onClose={onClose} screen={item} />}
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  )
}

export default ScreenItem
