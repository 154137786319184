import { useDispatch } from 'react-redux'
import { Navigate, useRoutes, Outlet, useNavigate } from 'react-router'
import ControlPanel from '../components/ControlPanel'
import BooksPanel from '../screens/Books'
import { fetchLocalizationsThunk } from '../redux/slices/localizations/thunks'
import {
  fetchGenresThunk,
  fetchSectionTypesThunk,
  fetchTagsThunk,
} from '../redux/slices/types/thunks'
import { fetchAdvertisementsThunk } from '../redux/slices/advertisements/thunks'
import { fetchAllBooksThunk } from '../redux/slices/books/thunks'
import { fetchBannersThunk } from '../redux/slices/banners/thunks'
import { fetchSeriesThunk } from '../redux/slices/series/thunks'
import SeriesPanel from '../screens/Series'
import BannersPanel from '../screens/Banners'
import AuthSevice from 'services/auth'
import { useEffect } from 'react'
import { fetcListsThunks } from 'redux/slices/lists/thunks'
import Cache from 'screens/Cache'
import ContentLocalization from 'screens/ContentLocalizations'
import AppNotifications from 'screens/AppNotifications'
import NavigationBar from 'components/NavigationBar'
import { Box, Flex } from '@chakra-ui/react'
import { USER_ROLES } from 'constants/users'
import Comments from 'screens/Comments'
import Screens from 'screens/Screens'
import Sections from 'screens/Sections'

const Admin = () => {
  const navigate = useNavigate()
  const isSupport = AuthSevice.role === USER_ROLES.SUPPORT

  useEffect(() => {
    if (isSupport) {
      navigate('/comments')
    }
  }, [isSupport, navigate])

  return (
    <div className='app-container'>
      <Flex
        w='100vw'
        h='100vh'
        bg='linear-gradient(135deg, #F3F1FF 0%, #F0FBFF 100%)'
        position='relative'
        direction='column'
        overflow='hidden'
      >
        <Box pos='fixed' left='48px' top='8px'>
          <NavigationBar />
        </Box>
        <Flex
          direction='column'
          gap='12px'
          p='0 0 0 162px'
          h='100%'
          w='100%'
          overflowX='hidden'
        >
          {!isSupport && <ControlPanel />}

          <Outlet />
        </Flex>
      </Flex>
    </div>
  )
}

export const PrivateRoutes = () => {
  const dispatch = useDispatch()

  const routes = useRoutes([
    {
      path: '/',
      element: <Admin />,
      children: [
        { path: 'screens', element: <Screens /> },
        { path: 'content-localizations', element: <ContentLocalization /> },
        { path: 'app-notifications', element: <AppNotifications /> },
        { path: 'books', element: <BooksPanel /> },
        { path: 'series', element: <SeriesPanel /> },
        { path: 'banners', element: <BannersPanel /> },
        { path: 'sections', element: <Sections /> },
        { path: 'cache', element: <Cache /> },
        { path: 'comments', element: <Comments /> },
      ],
    },
  ])

  useEffect(() => {
    if (AuthSevice.token && AuthSevice.role === USER_ROLES.ADMIN) {
      dispatch(fetchTagsThunk())
      dispatch(fetchLocalizationsThunk())
      dispatch(fetchGenresThunk())
      dispatch(fetchAdvertisementsThunk())
      dispatch(fetchAllBooksThunk())
      dispatch(fetchBannersThunk())
      dispatch(fetchSeriesThunk())
      dispatch(fetchSectionTypesThunk())
      dispatch(fetcListsThunks())
    }
  }, [dispatch])

  return AuthSevice.token ? routes : <Navigate to='sign-in' />
}
