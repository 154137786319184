import React, { useCallback, useEffect, useState } from 'react'
import {
  deleteBannerByIdThunk,
  updateBannerCoverThunk,
  updateBannerThunk,
} from '../../../../redux/slices/banners/thunks'
import { useDispatch } from 'react-redux'

import { BannerTypes } from '../../../../constants/banners'
import Button from '../../../../components/UI/Button'
import DropDown from '../../../../components/UI/DropDown'
import UpdateBannerModal from '../Modals/UpdateBannerModal'
import styles from './styles.module.scss'

const BannerItem = ({
  cover_url,
  banner_type,
  book,
  advertisement,
  banner_id,
}) => {
  const dispatch = useDispatch()

  const [isShowDropDownMenu, setIsShowDropDownMenu] = useState(false)
  const [isShowBannerUpdateModal, setIsShowBannerUpdateModal] = useState(false)

  const handleDropDownToggle = useCallback(() => {
    setIsShowDropDownMenu(!isShowDropDownMenu)
  }, [isShowDropDownMenu])

  const handleBannerUpdateModalShow = useCallback(() => {
    setIsShowDropDownMenu(false)
    setIsShowBannerUpdateModal(true)
  }, [])

  const handleBannerUpdateModalHide = useCallback(() => {
    setIsShowBannerUpdateModal(false)
  }, [])

  const handleDropDownClose = useCallback(() => {
    setIsShowDropDownMenu(false)
  }, [])

  const handleBannerDelete = useCallback(() => {
    setIsShowDropDownMenu(false)
    const isConfirm = window.confirm('Are you sure?')
    if (isConfirm) {
      dispatch(deleteBannerByIdThunk(banner_id))
    }
  }, [banner_id, dispatch])

  const handleBannerUpdate = useCallback(
    async ({ banner_type, file, attachment, cover_url }) => {
      const banner = {
        banner_id,
        banner_type,
        cover_url,
      }

      if (banner_type === BannerTypes.BOOKS) {
        banner.book = attachment
      }

      if (banner_type === BannerTypes.ADVERTISEMENTS) {
        banner.advertisement = attachment
      }

      await dispatch(updateBannerThunk(banner))
      if (file) {
        await dispatch(updateBannerCoverThunk({ banner_id, formdata: file }))
      }

      handleBannerUpdateModalHide()
    },
    [banner_id, dispatch, handleBannerUpdateModalHide],
  )

  useEffect(() => {
    document.body.addEventListener('scroll', handleDropDownToggle)

    return () => {
      document.body.removeEventListener('scroll', handleDropDownToggle)
    }
  }, [handleDropDownToggle])

  return (
    <div className={styles.banner}>
      <div className={styles.buttonContainer}>
        <Button onClick={handleDropDownToggle} theme='white'>
          <img src='/images/ellipsis.svg' alt='ellipsis' />
        </Button>
      </div>
      <img src={cover_url} alt='banner-cover' className={styles.bannerCover} />
      <div className={styles.bannerInfo}>
        <div className={styles.bannerType}>
          <b>Banner type:</b> {banner_type}
        </div>
        {banner_type === BannerTypes.ADVERTISEMENTS && (
          <div className={styles.bannerAdvertisement}>
            <div className={styles.bannerAdvertisementTitle}>
              <b> title: </b> {advertisement.title}
            </div>
            <div className={styles.bannerAdvertisementLink}>
              <b> Link: </b>{' '}
              <a href={advertisement.link} target='_blank' rel='noreferrer'>
                {advertisement.link}
              </a>
            </div>
          </div>
        )}
        {banner_type === BannerTypes.BOOKS && (
          <div>
            <p className={styles.bookTitle}>
              <b>Book title: </b>
              {book.title}
            </p>
            <img
              src={book.cover_link}
              alt='book-cover'
              className={styles.bookCover}
            />
          </div>
        )}
      </div>
      <DropDown
        handleDropDownClose={handleDropDownClose}
        show={isShowDropDownMenu}
        className='banner'
      >
        <DropDown.Option onClick={handleBannerUpdateModalShow}>
          Edit
        </DropDown.Option>
        <DropDown.Option onClick={handleBannerDelete} className='delete'>
          Delete
        </DropDown.Option>
      </DropDown>
      <UpdateBannerModal
        isShow={isShowBannerUpdateModal}
        onHide={handleBannerUpdateModalHide}
        book={book}
        banner_type={banner_type}
        advertisement={advertisement}
        onSubmit={handleBannerUpdate}
      />
    </div>
  )
}

export default BannerItem
