import React, { memo } from 'react'
import { Flex, Heading, Image, Text } from '@chakra-ui/react'
import { colors } from 'shared/style/colors'

const BooksListItem = ({
  book_id,
  cover_link,
  title,
  status,
  handleCopyBookIdClipboard,
  handleBookModalShow,
}) => (
  <Flex
    w='500px'
    onClick={handleBookModalShow}
    border={`1px solid ${colors['primary-gray-30']}`}
    p='8px 12px'
    boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
    direction='column'
    borderRadius='8px'
    gap='8px'
    cursor='pointer'
    _hover={{
      bg: 'rgba(36, 28, 88, 0.03)',
    }}
  >
    <Flex gap='8px'>
      <Image
        src={cover_link || '/images/book.png'}
        alt='book-cover'
        w='102px'
        h='144px'
        objectFit='cover'
      />

      <Flex w='100%' gap='10px' direction='column'>
        <Heading maxW='300px' size='poppins24' variant='primary-black-text'>
          {title}
        </Heading>

        <Text
          size='poppins18'
          variant='primary-black-text'
          p='4px'
          border={`1px solid ${colors['primary-gray-30']}`}
          borderRadius='200px'
          alignSelf='flex-end'
        >
          {status}
        </Text>
      </Flex>
    </Flex>
    <Flex gap='16px'>
      <Text as='span' size='poppins18' variant='primary-black-text'>
        book_id:
      </Text>
      <Text
        as='span'
        size='poppins16normal'
        variant='primary-black-text'
        onClick={e => {
          e.stopPropagation()
          handleCopyBookIdClipboard(book_id)
        }}
        cursor='copy'
      >
        {book_id}
      </Text>
    </Flex>
  </Flex>
)
export default memo(BooksListItem)
