import { USER_ROLES } from 'constants/users'

export const navigation = [
  {
    name: 'Sections',
    path: '/sections',
    permission: USER_ROLES.ADMIN,
    inactive: (fill = '#02012B') => (
      <svg
        fill={fill}
        viewBox='0 0 32 32'
        id='Layer_2'
        xmlns='http://www.w3.org/2000/svg'
        stroke={fill}
      >
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g
          id='SVGRepo_tracerCarrier'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></g>
        <g id='SVGRepo_iconCarrier'>
          <title></title>
          <path d='M10.64,14.52a1.25,1.25,0,0,0,0,2.5A1.25,1.25,0,0,0,10.64,14.52Z'></path>
          <path d='M16.09,14.52a1.25,1.25,0,0,0,0,2.5A1.25,1.25,0,0,0,16.09,14.52Z'></path>
          <path d='M22.17,14.68a1.25,1.25,0,0,0,0,2.5A1.25,1.25,0,0,0,22.17,14.68Z'></path>
        </g>
      </svg>
    ),
    active: '/images/hero/list.svg',
  },
  {
    name: 'Screens',
    path: '/screens',
    permission: USER_ROLES.ADMIN,
    inactive: (fill = '#02012B') => (
      <svg
        viewBox='0 0 24 24'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        fill='#000000'
      >
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g
          id='SVGRepo_tracerCarrier'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></g>
        <g id='SVGRepo_iconCarrier'>
          <defs>
            <style>
              {`.cls-1{fill:none;stroke:${fill};stroke-miterlimit:10;stroke-width:1.91px;}`}
            </style>
          </defs>
          <rect
            className='cls-1'
            x='5.32'
            y='1.5'
            width='13.36'
            height='21'
            rx='1.91'
          ></rect>
          <path
            className='cls-1'
            d='M13.91,2.45H10.09a.94.94,0,0,1-.95-1h5.72A.94.94,0,0,1,13.91,2.45Z'
          ></path>
          <polyline
            className='cls-1'
            points='13.91 10.57 13.91 14.86 10.09 14.86 10.09 10.57'
          ></polyline>
          <polyline
            className='cls-1'
            points='8.18 12 12 9.14 15.82 12'
          ></polyline>
        </g>
      </svg>
    ),
    active: '/images/hero/mainscreen.svg',
  },
  {
    name: 'Localizations',
    path: '/content-localizations',
    permission: USER_ROLES.ADMIN,
    inactive: (fill = '#02012B') => {
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            d='M16.9897 9.71094H7.00977C6.59977 9.71094 6.25977 9.37094 6.25977 8.96094C6.25977 8.55094 6.59977 8.21094 7.00977 8.21094H16.9897C17.3997 8.21094 17.7397 8.55094 17.7397 8.96094C17.7397 9.37094 17.3997 9.71094 16.9897 9.71094Z'
            fill={fill}
            style={{ transition: 'fill 0.4s ease' }}
          />
          <path
            d='M12 9.71124C11.59 9.71124 11.25 9.37124 11.25 8.96124V7.28125C11.25 6.87125 11.59 6.53125 12 6.53125C12.41 6.53125 12.75 6.87125 12.75 7.28125V8.96124C12.75 9.37124 12.41 9.71124 12 9.71124Z'
            fill={fill}
            style={{ transition: 'fill 0.4s ease' }}
          />
          <path
            d='M7 17.4714C6.59 17.4714 6.25 17.1314 6.25 16.7214C6.25 16.3114 6.59 15.9714 7 15.9714C10.72 15.9714 13.75 12.8214 13.75 8.94141C13.75 8.53141 14.09 8.19141 14.5 8.19141C14.91 8.19141 15.25 8.53141 15.25 8.94141C15.25 13.6514 11.55 17.4714 7 17.4714Z'
            fill={fill}
            style={{ transition: 'fill 0.4s ease' }}
          />
          <path
            d='M17.0002 17.4702C15.0302 17.4702 13.2002 16.4903 11.8602 14.7003C11.6102 14.3703 11.6802 13.9003 12.0102 13.6503C12.3402 13.4003 12.8102 13.4702 13.0602 13.8002C14.1202 15.2002 15.5202 15.9702 17.0102 15.9702C17.4202 15.9702 17.7602 16.3102 17.7602 16.7202C17.7602 17.1302 17.4102 17.4702 17.0002 17.4702Z'
            fill={fill}
            style={{ transition: 'fill 0.4s ease' }}
          />
          <path
            d='M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z'
            fill={fill}
            style={{ transition: 'fill 0.4s ease' }}
          />
        </svg>
      )
    },
    active: '/images/hero/localization.svg',
  },
  {
    name: 'Notifications',
    path: '/app-notifications',
    permission: USER_ROLES.ADMIN,
    inactive: fill => (
      <svg
        fill={fill}
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 210 256'
        enableBackground='new 0 0 210 256'
        xmlSpace='preserve'
        stroke='#02012B'
      >
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g
          id='SVGRepo_tracerCarrier'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></g>
        <g id='SVGRepo_iconCarrier'>
          <path d='M174.55,144.5L174.55,144.5c-1.4-4-5-6.5-9.2-6.5H159V23c0-11.708-9.292-21-21-21H25C12.57,2,2,12.57,2,25v183 c0,11.9,10.95,22,22.75,22l114.213,0c1.207,0,2.27,0.984,2.18,2.188c-0.095,1.266-1.153,1.812-2.393,1.812h-45.5L128,254h80 L174.55,144.5z M82.05,220.2c-3.199,0-5.599-2.399-5.6-5.598c-0.001-3.045,2.557-5.602,5.602-5.602 c3.199,0.001,5.598,2.401,5.598,5.6C87.55,217.8,85.25,220.2,82.05,220.2z M144,138h-19.65c-5.3,0-9.8,4.7-9.8,10l0,0 c0,5.3,4.5,10,9.8,10h19.8v42H18V31h126V138z M69.446,120.891h21.358c-0.919,5.064-5.349,8.906-10.679,8.906 S70.366,125.956,69.446,120.891z M109.973,116.904H50.276c-7.248,0-10.872-8.786-5.758-13.901c3.775-3.775,8.464-5.588,8.464-23.93 c0-13.469,9.811-24.644,22.675-26.775h0.702c-0.82-0.903-1.32-2.102-1.32-3.417c0-2.808,2.277-5.085,5.085-5.085 c2.808,0,5.085,2.277,5.085,5.085c0,1.316-0.5,2.515-1.32,3.417h0.702c12.864,2.131,22.675,13.306,22.675,26.775 c0,18.341,4.688,20.155,8.462,23.93C120.855,108.129,117.206,116.904,109.973,116.904z'></path>
        </g>
      </svg>
    ),
    active: '/images/hero/notification.svg',
  },
  {
    name: 'Books',
    path: '/books',
    permission: USER_ROLES.ADMIN,
    inactive: fill => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M3.5 18.75C3.09 18.75 2.75 18.41 2.75 18V7C2.75 2.59 4.09 1.25 8.5 1.25H15.5C19.91 1.25 21.25 2.59 21.25 7V17C21.25 17.16 21.25 17.31 21.24 17.47C21.21 17.88 20.84 18.2 20.44 18.17C20.03 18.14 19.71 17.78 19.74 17.37C19.75 17.25 19.75 17.12 19.75 17V7C19.75 3.43 19.08 2.75 15.5 2.75H8.5C4.92 2.75 4.25 3.43 4.25 7V18C4.25 18.41 3.91 18.75 3.5 18.75Z'
          fill={fill}
          style={{ transition: 'fill 0.4s ease' }}
        />
        <path
          d='M17 22.75H7C4.66 22.75 2.75 20.84 2.75 18.5V17.85C2.75 15.86 4.37 14.25 6.35 14.25H20.5C20.91 14.25 21.25 14.59 21.25 15V18.5C21.25 20.84 19.34 22.75 17 22.75ZM6.35 15.75C5.19 15.75 4.25 16.69 4.25 17.85V18.5C4.25 20.02 5.48 21.25 7 21.25H17C18.52 21.25 19.75 20.02 19.75 18.5V15.75H6.35Z'
          fill={fill}
          style={{ transition: 'fill 0.4s ease' }}
        />
        <path
          d='M16 7.75H8C7.59 7.75 7.25 7.41 7.25 7C7.25 6.59 7.59 6.25 8 6.25H16C16.41 6.25 16.75 6.59 16.75 7C16.75 7.41 16.41 7.75 16 7.75Z'
          fill={fill}
          style={{ transition: 'fill 0.4s ease' }}
        />
        <path
          d='M13 11.25H8C7.59 11.25 7.25 10.91 7.25 10.5C7.25 10.09 7.59 9.75 8 9.75H13C13.41 9.75 13.75 10.09 13.75 10.5C13.75 10.91 13.41 11.25 13 11.25Z'
          fill={fill}
          style={{ transition: 'fill 0.4s ease' }}
        />
      </svg>
    ),
    active: '/images/hero/stories-active.svg',
  },
  {
    name: 'Series',
    path: '/series',
    permission: USER_ROLES.ADMIN,
    inactive: (fill = '#02012B') => {
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
        >
          <path
            d='M21.5 10.225C21.5 9.925 21.5 8.925 21.5 8.225V4.725C21.5 3.925 21.2 3.225 20.6 2.725C20 2.225 19.3 1.925 18.6 2.025H18.5C16.3 2.225 13 3.325 11.1 4.525L10.9 4.625C10.8 4.625 10.7 4.625 10.6 4.625L10.4 4.525C8.5 3.325 5.2 2.225 3 2.025C2.2 1.925 1.5 2.125 0.9 2.625C0.3 3.125 0 3.825 0 4.625V16.725C0 18.025 1.1 19.325 2.4 19.425H2.7C4.8 19.725 8.1 20.825 9.9 21.825C10.1 21.925 10.4 22.025 10.7 22.025C11 22.025 11.3 21.925 11.5 21.825C12 21.525 11.9 21.525 12.4 21.325C13 21.025 13.3645 20.9831 13.2 20.325C13.1 19.925 12.8 19.625 12.4 19.725C11.3 20.125 11.7 20.125 10.8 20.525C10.8 20.525 10.6 20.525 10.5 20.525C8.5 19.425 5 18.325 2.8 18.025H2.5C1.9 17.925 1.4 17.425 1.4 16.825V4.725C1.4 4.325 1.5 4.025 1.8 3.825C2 3.625 2.3 3.525 2.6 3.525H2.7C4.7 3.725 7.7 4.725 9.5 5.725L9.7 5.825C10.2 6.125 11 6.125 11.6 5.825L11.8 5.725C13.5 4.725 16.6 3.625 18.5 3.525H18.6C19 3.525 19.3 3.625 19.6 3.825C19.8 4.025 20 4.425 20 4.725V11.025C20 11.525 20.6 11.625 20.8 11.625C21.1 11.625 21.3 11.425 21.4 11.225C21.4802 11.0647 21.5 11.025 21.5 10.225Z'
            fill={fill}
            style={{ transition: 'fill 0.4s ease' }}
          />
          <path
            d='M10.8 21.925C10.4 21.925 10 21.625 10 21.125V6.125C10 5.725 10.3 5.325 10.8 5.325C11.2 5.325 11.6 5.625 11.6 6.125V21.125C11.5 21.625 11.2 21.925 10.8 21.925Z'
            fill={fill}
            style={{ transition: 'fill 0.4s ease' }}
          />
          <path
            d='M6.5 9.925H4.3C3.9 9.925 3.5 9.625 3.5 9.125C3.5 8.725 3.8 8.325 4.3 8.325H6.6C7 8.325 7.4 8.625 7.4 9.125C7.3 9.625 6.9 9.925 6.5 9.925Z'
            fill={fill}
            style={{ transition: 'fill 0.4s ease' }}
          />
          <path
            d='M7.3 12.925H4.3C3.9 12.925 3.5 12.625 3.5 12.125C3.5 11.725 3.8 11.325 4.3 11.325H7.3C7.7 11.325 8.1 11.625 8.1 12.125C8 12.625 7.7 12.925 7.3 12.925Z'
            fill={fill}
            style={{ transition: 'fill 0.4s ease' }}
          />
          <path
            d='M16.4978 22.0535C16.1178 22.0535 15.7578 21.9135 15.4978 21.6535C15.1878 21.3435 15.0478 20.8934 15.1178 20.4234L15.3078 19.0734C15.3578 18.7234 15.5678 18.3035 15.8178 18.0535L19.3578 14.5134C19.8378 14.0334 20.3078 13.7834 20.8178 13.7334C21.4378 13.6734 22.0578 13.9334 22.6378 14.5134C23.2178 15.0934 23.4778 15.7034 23.4178 16.3334C23.3678 16.8334 23.1078 17.3134 22.6378 17.7934L19.0978 21.3334C18.8478 21.5834 18.4278 21.7935 18.0778 21.8435L16.7278 22.0335C16.6478 22.0435 16.5778 22.0535 16.4978 22.0535ZM20.9878 15.2235C20.9778 15.2235 20.9678 15.2235 20.9578 15.2235C20.8178 15.2335 20.6278 15.3634 20.4178 15.5734L16.8778 19.1134C16.8478 19.1434 16.7978 19.2435 16.7978 19.2835L16.6178 20.5335L17.8678 20.3534C17.9078 20.3434 18.0078 20.2934 18.0378 20.2634L21.5778 16.7235C21.7878 16.5035 21.9178 16.3235 21.9278 16.1835C21.9478 15.9835 21.7478 15.7434 21.5778 15.5734C21.4178 15.4134 21.1878 15.2235 20.9878 15.2235Z'
            fill={fill}
            style={{ transition: 'fill 0.4s ease' }}
          />
          <path
            d='M21.5978 18.5234C21.5278 18.5234 21.4578 18.5134 21.3978 18.4934C20.0778 18.1234 19.0278 17.0734 18.6578 15.7534C18.5478 15.3534 18.7778 14.9434 19.1778 14.8234C19.5778 14.7134 19.9878 14.9434 20.0978 15.3434C20.3278 16.1634 20.9778 16.8134 21.7978 17.0434C22.1978 17.1534 22.4278 17.5734 22.3178 17.9734C22.2278 18.3034 21.9278 18.5234 21.5978 18.5234Z'
            fill={fill}
            style={{ transition: 'fill 0.4s ease' }}
          />
        </svg>
      )
    },
    active: '/images/hero/requests.svg',
  },
  {
    name: 'Banners',
    path: '/banners',
    permission: USER_ROLES.ADMIN,
    inactive: (fill = '#02012B') => (
      <svg
        fill={fill}
        height='24px'
        width='24px'
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 512 512'
        xmlSpace='preserve'
        stroke={fill}
      >
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g
          id='SVGRepo_tracerCarrier'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></g>
        <g id='SVGRepo_iconCarrier'>
          <g>
            <g>
              <path d='M481.806,286.348l29.618-85.491c1.108-3.197,0.6-6.471-1.363-9.227c-1.962-2.757-5.136-4.132-8.519-4.132H397.186V135.73 c0-5.776-4.455-9.935-10.231-9.935H125.499c-5.776,0-10.685,4.159-10.685,9.935v51.768H10.519c-3.382,0-6.556,1.374-8.518,4.13 c-1.962,2.755-2.471,6.159-1.366,9.355l29.558,85.392l-29.617,85.46c-1.107,3.197-0.6,6.977,1.363,9.734 c1.962,2.757,5.136,4.638,8.519,4.638h115.041c5.776,0,10.231-5.205,10.231-10.981v-50.722h240.54v50.722 c0,5.776,4.909,10.981,10.685,10.981H501.48c3.382,0,6.556-1.897,8.518-4.653c1.962-2.755,2.471-6.42,1.366-9.616L481.806,286.348 z M25.149,365.289l25.993-75.292c0.768-2.216,0.768-4.495,0.001-6.711l-25.942-74.871h89.611v156.874H25.149z M156.647,303.586 H135.73V197.434v-50.722h20.917V303.586z M334.437,303.586H177.563V146.712h156.874V303.586z M376.27,197.434v106.151h-20.916 V146.712h20.916V197.434z M397.186,365.289V208.416h89.664l-25.993,74.769c-0.768,2.216-0.768,4.756-0.001,6.972l25.942,75.132 H397.186z'></path>
            </g>
          </g>
        </g>
      </svg>
    ),
    active: '/images/hero/banner.svg',
  },
  {
    name: 'Cache',
    path: '/cache',
    permission: USER_ROLES.ADMIN,
    inactive: (fill = '#02012B') => (
      <svg
        viewBox='0 0 48 48'
        id='b'
        xmlns='http://www.w3.org/2000/svg'
        fill={fill}
      >
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g
          id='SVGRepo_tracerCarrier'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></g>
        <g id='SVGRepo_iconCarrier'>
          <defs>
            <style>
              {`.t { fill: none; stroke: ${fill}; strokeLinecap: round; strokeLinejoin:round;} `}
            </style>
          </defs>
          <circle
            id='c'
            className='t'
            cx='36.096'
            cy='22.6536'
            r='1.3931'
          ></circle>
          <circle
            id='d'
            className='t'
            cx='40.2225'
            cy='26.8135'
            r='1.6717'
          ></circle>
          <path id='e' className='t' d='m12.2134,10.4649l11.3032,1.9794'></path>
          <path id='f' className='t' d='m15.5471,14.4757l7.3445,1.3022'></path>
          <path id='g' className='t' d='m13.4115,17.3406l8.9592,1.5627'></path>
          <path
            id='h'
            className='t'
            d='m28.9503,31.9961l-.2768-3.4044c-.6946-4.6049-6.0126-5.7231-7.657-1.2762-1.8215,3.7646-8.3136,7.2818-15.4182,8.1518,2.8623,9.2457,18.1701,7.426,20.2625,4.7401'
          ></path>
          <path
            id='i'
            className='t'
            d='m26.9545,34.7903c-4.9816,2.8272-.2458,7.5687,2.6044,5.7297,2.0714,2.7765,6.4689,2.2491,7.5008.1563,5.8857,2.6255,7.2368-5.5731,2.3961-6.3027-1.076-3.7607-4.9677-4.4122-7.2924-2.1356-1.9877-.4898-4.0291-1.2665-5.2089,2.5524Z'
          ></path>
          <path id='j' className='t' d='m9.2965,17.4072v1.5105'></path>
          <path id='k' className='t' d='m9.2965,21.2702v1.5106'></path>
          <path id='l' className='t' d='m10.5248,20.094l1.5105.0522'></path>
          <path id='m' className='t' d='m6.6618,20.1461h1.5106'></path>
          <path
            id='n'
            className='t'
            d='m14.0712,27.9419l1.0313,1.105m0-3.8734l-1.0682,1.0681m-2.7315-.9945l1.0681,1.0681m0,1.5898l-1.0681,1.0682'
          ></path>
          <path
            id='o'
            className='t'
            d='m21.0164,27.3156c2.3279,1.9405,4.9381,1.9753,7.657,1.2762'
          ></path>
          <path
            id='p'
            className='t'
            d='m10.9776,41.135c5.6948-1.9298,9.2192-4.403,11.1848-7.2823'
          ></path>
          <path
            id='q'
            className='t'
            d='m22.1103,38.4886c-1.116,1.5442-2.4716,2.8254-4.7661,3.8785'
          ></path>
          <path
            id='r'
            className='t'
            d='m7.1015,38.3102c3.2416-.0909,5.7762-1.2165,8.2373-2.4782'
          ></path>
          <path
            id='s'
            className='t'
            d='m23.712,24.6163l3.2165-17.1726c.448-2.9721,3.9476-2.1183,3.2685.573l-3.3643,17.2741'
          ></path>
        </g>
      </svg>
    ),
    active: '/images/hero/clean-cache.svg',
  },
  {
    name: 'Comments',
    path: '/comments',
    permission: USER_ROLES.ALL,
    inactive: fill => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M8 22.3194C7.72 22.3194 7.42998 22.2494 7.16998 22.1094C6.59998 21.8094 6.25 21.2094 6.25 20.5694V19.1495C3.23 18.8395 1.25 16.6194 1.25 13.4394V7.43945C1.25 3.99945 3.56 1.68945 7 1.68945H17C20.44 1.68945 22.75 3.99945 22.75 7.43945V13.4394C22.75 16.8794 20.44 19.1894 17 19.1894H13.23L8.96997 22.0295C8.67997 22.2195 8.34 22.3194 8 22.3194ZM7 3.17944C4.42 3.17944 2.75 4.84944 2.75 7.42944V13.4295C2.75 16.0095 4.42 17.6795 7 17.6795C7.41 17.6795 7.75 18.0195 7.75 18.4295V20.5595C7.75 20.6895 7.83 20.7495 7.88 20.7795C7.93001 20.8095 8.03001 20.8395 8.14001 20.7695L12.59 17.8095C12.71 17.7295 12.86 17.6795 13.01 17.6795H17.01C19.59 17.6795 21.26 16.0095 21.26 13.4295V7.42944C21.26 4.84944 19.59 3.17944 17.01 3.17944H7Z'
          fill={fill}
          style={{ transition: 'fill 0.4s ease' }}
        />
        <path
          d='M11.9998 12.1094C11.5898 12.1094 11.2498 11.7694 11.2498 11.3594V11.1494C11.2498 9.98941 12.0998 9.4194 12.4198 9.1994C12.7898 8.9494 12.9098 8.77941 12.9098 8.51941C12.9098 8.01941 12.4998 7.60938 11.9998 7.60938C11.4998 7.60938 11.0898 8.01941 11.0898 8.51941C11.0898 8.92941 10.7498 9.26941 10.3398 9.26941C9.92984 9.26941 9.58984 8.92941 9.58984 8.51941C9.58984 7.18941 10.6698 6.10938 11.9998 6.10938C13.3298 6.10938 14.4098 7.18941 14.4098 8.51941C14.4098 9.65941 13.5698 10.2294 13.2598 10.4394C12.8698 10.6994 12.7498 10.8694 12.7498 11.1494V11.3594C12.7498 11.7794 12.4098 12.1094 11.9998 12.1094Z'
          fill={fill}
          style={{ transition: 'fill 0.4s ease' }}
        />
        <path
          d='M12 14.5996C11.58 14.5996 11.25 14.2596 11.25 13.8496C11.25 13.4396 11.59 13.0996 12 13.0996C12.41 13.0996 12.75 13.4396 12.75 13.8496C12.75 14.2596 12.42 14.5996 12 14.5996Z'
          fill={fill}
          style={{ transition: 'fill 0.4s ease' }}
        />
      </svg>
    ),
    active: '/images/hero/help-active.svg',
  },
]

export const navigationLogout = {
  name: 'Logout',
  inactive: (fill = '#02012B') => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M15.24 22.2705H15.11C10.67 22.2705 8.53002 20.5205 8.16002 16.6005C8.12002 16.1905 8.42002 15.8205 8.84002 15.7805C9.24002 15.7405 9.62002 16.0505 9.66002 16.4605C9.95002 19.6005 11.43 20.7705 15.12 20.7705H15.25C19.32 20.7705 20.76 19.3305 20.76 15.2605V8.74047C20.76 4.67047 19.32 3.23047 15.25 3.23047H15.12C11.41 3.23047 9.93002 4.42047 9.66002 7.62047C9.61002 8.03047 9.26002 8.34047 8.84002 8.30047C8.42002 8.27047 8.12001 7.90047 8.15001 7.49047C8.49001 3.51047 10.64 1.73047 15.11 1.73047H15.24C20.15 1.73047 22.25 3.83047 22.25 8.74047V15.2605C22.25 20.1705 20.15 22.2705 15.24 22.2705Z'
        fill={fill}
        style={{ transition: 'fill 0.4s ease' }}
      />
      <path
        d='M15.0001 12.75H3.62012C3.21012 12.75 2.87012 12.41 2.87012 12C2.87012 11.59 3.21012 11.25 3.62012 11.25H15.0001C15.4101 11.25 15.7501 11.59 15.7501 12C15.7501 12.41 15.4101 12.75 15.0001 12.75Z'
        fill={fill}
        style={{ transition: 'fill 0.4s ease' }}
      />
      <path
        d='M5.84994 16.0998C5.65994 16.0998 5.46994 16.0298 5.31994 15.8798L1.96994 12.5298C1.67994 12.2398 1.67994 11.7598 1.96994 11.4698L5.31994 8.11984C5.60994 7.82984 6.08994 7.82984 6.37994 8.11984C6.66994 8.40984 6.66994 8.88984 6.37994 9.17984L3.55994 11.9998L6.37994 14.8198C6.66994 15.1098 6.66994 15.5898 6.37994 15.8798C6.23994 16.0298 6.03994 16.0998 5.84994 16.0998Z'
        fill={fill}
        style={{ transition: 'fill 0.4s ease' }}
      />
    </svg>
  ),
  active: '',
  path: '',
}
