import { Flex, Image, Text, useMediaQuery } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { colors } from 'shared/style/colors'
import { FW } from 'shared/style/fw'

const NavigationButton = ({ isActive, navElement }) => {
  const navigate = useNavigate()
  const { active, inactive, name, path } = navElement
  const [fill, setFill] = useState(colors['primary-black-text'])
  const [fontColor, setFontColor] = useState('primary-black-text')
  const [isSmallDevice] = useMediaQuery('(max-height: 790px)')

  const handleNavigate = () => {
    navigate(path)
  }

  const handleStartHover = () => {
    setFill(colors['button-hover'])
    setFontColor('button-hover')
  }

  const handleEndHover = () => {
    setFill(colors['primary-black-text'])
    setFontColor('primary-black-text')
  }

  return (
    <Flex
      maxW='90px'
      p='4px'
      align='center'
      justify='center'
      direction='column'
      gap='4px'
      cursor='pointer'
      onClick={handleNavigate}
      onMouseEnter={handleStartHover}
      onMouseLeave={handleEndHover}
    >
      {!isSmallDevice && (
        <Flex w='32px' h='32px' align='center' justify='center' pos='relative'>
          {isActive ? (
            <Image w='32px' h='32px' src={active} alt={name} />
          ) : (
            inactive(fill)
          )}
        </Flex>
      )}

      <Text
        size='poppins12'
        variant={isActive ? 'primary-violet-100' : fontColor}
        lineHeight='24px'
        fontWeight={isActive ? FW[600] : FW[400]}
        transition='all 0.4s ease'
      >
        {name}
      </Text>
    </Flex>
  )
}

export default NavigationButton
