import React, { memo, useCallback, useState } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import BookItem from '../BookItem'
import './styles.scss'
import { Flex, Text, useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { updateListThunk } from 'redux/slices/lists/thunks'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { apolloClient } from 'app/hocs/with-apollo'
import { GET_LIST } from 'services/lists/graphql'
import { baseScrollBar } from 'constants/scrollbar'

const BooksSection = ({
  list,
  openAppendBookModal,
  deleteList,
  deleteListContentOption,
}) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handleCopyBookIdClipboard = useCallback(
    text => {
      navigator.clipboard.writeText(text)
      toast({
        title: `Book Id copied to clipboard!`,
        status: 'success',
        isClosable: true,
        duration: 1000,
        position: 'top',
      })
    },
    [toast],
  )

  const handleOnDragBook = useCallback(
    async result => {
      const { source, destination } = result

      if (!destination || source.index === destination.index) return

      setIsLoading(true)

      const items = [...list.booksOrder].sort(
        (orderA, orderB) => orderA.order_number - orderB.order_number,
      )
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)
      const final = items.map(({ book }, order_number) => ({
        book: { book_id: book.book_id },
        order_number,
      }))

      const updatedList = {
        list_id: list.list_id,
        title: list.title,
        booksOrder: final,
      }

      await dispatch(updateListThunk(updatedList))
      await apolloClient.query({
        query: GET_LIST,
        variables: { list_id: list.list_id },
        fetchPolicy: 'network-only',
      })
      setIsLoading(false)
    },
    [dispatch, list],
  )

  return (
    <Accordion.Item eventKey={list.list_id}>
      <Accordion.Header>
        <div style={{ flex: '1' }}>
          <Flex gap='16px'>
            <Text as='span' size='poppins18' variant='primary-black-text'>
              list_id:
            </Text>
            <Text
              as='span'
              size='poppins16normal'
              variant='primary-black-text'
              onClick={e => {
                e.stopPropagation()
                handleCopyBookIdClipboard(list.list_id)
              }}
              cursor='copy'
            >
              {list.list_id}
            </Text>
          </Flex>
          <br />
          Title: {list.title}
          <br />
          <div>Description: {list.description}</div>
          <small style={{ textTransform: 'capitalize' }}>
            Type: {list.list_type}
          </small>
        </div>
        <Button
          variant='danger'
          onClick={deleteList}
          style={{ marginTop: 0, marginRight: 30 }}
        >
          Delete list
        </Button>
      </Accordion.Header>

      <Accordion.Body
        style={{
          height: '100%',
          maxHeight: '60vh',
          overflowY: 'auto',
        }}
      >
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <DragDropContext onDragEnd={result => handleOnDragBook(result)}>
            <Droppable
              droppableId='list-books'
              style={{
                transform: 'none',
                height: '100%',
              }}
            >
              {provided => (
                <Flex
                  direction='column'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  userSelect='none'
                  gap='10px'
                  width='100%'
                  h='100%'
                  overflowY='auto'
                  sx={baseScrollBar}
                >
                  {[...list.booksOrder]
                    .sort(
                      (orderA, orderB) =>
                        orderA.order_number - orderB.order_number,
                    )
                    .map(({ book }, index) => {
                      return (
                        <Draggable
                          key={book.book_id}
                          draggableId={book.book_id}
                          index={index}
                        >
                          {provided => (
                            <BookItem
                              book={book}
                              elementRef={provided.innerRef}
                              draggableProps={provided.draggableProps}
                              dragHandleProps={provided.dragHandleProps}
                              onDelete={() =>
                                deleteListContentOption(list, book.book_id)
                              }
                            />
                          )}
                        </Draggable>
                      )
                    })}
                  {provided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <Button onClick={openAppendBookModal}>Add books</Button>
      </Accordion.Body>
    </Accordion.Item>
  )
}
export default memo(BooksSection)
